import React, {useContext, useEffect, useState} from 'react'
import './Bestsellers.css'

import Item from '../Item/Item';

const Bestsellers = (props) => {
    const api_url = process.env.REACT_APP_API_URL;
    const api_key = process.env.REACT_APP_API_KEY;
    const client_id = process.env.REACT_APP_API_CLIENT_ID;

    const [bestsellers, setBestsellers] = useState([]);

    useEffect(() => {
        fetch(`${api_url}/bestsellers`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': api_key,
                'x-client-id': client_id,
            },
        })
        .then((res) => res.json())
        .then((data) => {
            if (data.success) {
                data.products.reverse();
                setBestsellers(data.products);
            }
        });
    }, []);

  return (
    <div className='bestsellers'>
        <div className="section-head">
            <h1 className='section-title'>Best <span>Sellers</span></h1>
            <h3 className='section-description'>Discover our bestsellers that are loved by our customers.</h3>
        </div>
        <div className="products">
            <div className="items">
            {bestsellers && bestsellers.length>0 ? bestsellers.map((item,i)=>{
                return <Item key={i} pid={item.pid} name={item.name} description={item.description} images={item.images} colors={item.colors}/>
            }) : null}
            </div>
        </div>
    </div>
  )
}

export default Bestsellers
