import React, { useEffect, useState } from 'react';
import './NewArrivals.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as fas from '@fortawesome/free-solid-svg-icons';
import $ from 'jquery';

const NewArrivals = () => {
    const api_url = process.env.REACT_APP_API_URL;
    const api_key = process.env.REACT_APP_API_KEY;
    const client_id = process.env.REACT_APP_API_CLIENT_ID;

    const [newArrivals, setNewArrivals] = useState([]);
    const [arrivalGroups, setArrivalGroups] = useState([]);
    const totalArrival = 9;

    useEffect(() => {
        fetch(`${api_url}/newarrivals`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': api_key,
                'x-client-id': client_id,
            },
        })
        .then((res) => res.json())
        .then((data) => {
            if (data.success) {
                data.products.reverse();
                setNewArrivals(data.products.slice(0, totalArrival));
            }
        });
    }, []);

    useEffect(() => {
        if (newArrivals && newArrivals.length>0 && arrivalGroups && arrivalGroups.length===0) {
            let groups = [];
            let i = 0;
            while (i < newArrivals.length) {
                groups.push(newArrivals.slice(i, i + 3));
                i += 3;
            }
            setArrivalGroups(groups);
        }
    }, [newArrivals]);

    useEffect(() => {
    const carouselItems = $('.carousel-item');

    function animateCarousel() {
        carouselItems.each(function (index) {
            const $this = $(this);
            setTimeout(() => {
                $this.animate({ width: '60%' }, 1000, function () {
                    setTimeout(() => { // Introduce a delay of 4 seconds before transitioning to 10% width
                        $(this).animate({ width: '10%' }, 1000, function() {
                            // Execute sliding the banner once carousel animation is complete
                            if (index === carouselItems.length - 4) {
                                slideBanner();
                            }
                        });
                    }, 2000);
                });
            }, index * 4000); // Adjust the delay as needed
        });
    }

    let arrivals_currentIndex = 0;
    const arrivals_banners = $('.new-arrivals .arrivals-con .banner');
    const arrivals_dots = $('.new-arrivals .arrivals-con .slider-position .slider-position-dot');

    function slideBanner() {
        arrivals_banners.removeClass('active');
        arrivals_dots.removeClass('active');
        arrivals_currentIndex++;
        if (arrivals_currentIndex >= arrivals_banners.length) {
            arrivals_currentIndex = 0;
        }
        $('.new-arrivals .arrivals-con .banner').css('transform', 'translateX(' + (-arrivals_currentIndex * 100) + '%)');
        arrivals_banners.eq(arrivals_currentIndex).addClass('active');
        arrivals_dots.eq(arrivals_currentIndex).addClass('active');
    }

    animateCarousel();

    const carouselInterval = setInterval(animateCarousel, 12000); // Adjust interval as needed

    return () => clearInterval(carouselInterval);
  }, [arrivalGroups]);

  useEffect(() => {
    const itemDetail = $('.item-detail');
    
    function animateDetail() {
        itemDetail.each(function (index) {
        const $this = $(this);
        setTimeout(() => {
            $this.fadeIn(1000, function () {
            setTimeout(() => { // Introduce a delay of 4 seconds before transitioning to 10% width
                // fadeOut the item detail
                $(this).fadeOut(1000);
            }, 2000);
            });
        }, index * 4000); // Adjust the delay as needed
        });
    }

    animateDetail();

    const detailInterval = setInterval(animateDetail, 12000); // Adjust interval as needed

    return () => clearInterval(detailInterval);
    }, [arrivalGroups]);

    const handleViewProduct = (pid) => {
        window.open(`/product/${pid}`, '_blank');
    }

  return (
    <div className='new-arrivals'>
        <div className="section-head">
            <h1 className='section-title'>
                New <span>Arrivals</span>
            </h1>
            <h3 className='section-description'>Peep the freshest drops in our New Arrivals - stay ahead of the game, fam!</h3>
        </div>
        <div className="arrivals-con">
            {arrivalGroups && arrivalGroups.length > 0 && newArrivals && newArrivals.length > 0 && arrivalGroups.map((arrivalGroup, groupIndex) => {
                if (arrivalGroup.length === 3) {
                    return (
                        <><div className={`banner banner${groupIndex + 1}`} key={groupIndex}>
                            <div className="arrival">
                                <div className="carousel">
                                    {arrivalGroup.map((arrival, arrivalIndex) => (
                                        <div key={arrivalIndex} className={`carousel-item carousel-item${arrivalIndex + 1}`} onClick={()=>handleViewProduct(arrival.pid)}>
                                            <img src={arrival.colors[0].images[0]} alt='' />
                                        </div>
                                    ))}
                                </div>
                                <div className="arrival-detail">
                                    {arrivalGroup.map((arrival, arrivalIndex) => (
                                        <div key={arrivalIndex} className={`item-detail item-detail${arrivalIndex + 1}`} onClick={()=>handleViewProduct(arrival.pid)}>
                                            <div className="item-name">
                                                <span>{arrival.name}</span>
                                            </div>
                                            <div className="item-price">
                                                <span>&#8377; {arrival.colors[0].sizes[0].sellingPrice}</span>
                                            </div>
                                            <div className="item-description">
                                                <p>{arrival.description}</p>
                                            </div>
                                            <div className="wishlist" onClick={()=>handleViewProduct(arrival.pid)}>
                                                <FontAwesomeIcon icon={fas.faUpRightFromSquare} />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className='slider-position'>
                            {arrivalGroups && arrivalGroups.length > 1 && arrivalGroup[1].length===3 ? arrivalGroups.map((arrivalGroup, index) => (
                                arrivalGroup.length === 3 ? (<div className={`slider-position-dot ${index === groupIndex ? 'active' : ''}`} key={index}></div>) : null
                            )) : null}
                        </div></>
                    );
                }
                return null; // Add this line to avoid returning undefined
            })}
        </div>
    </div>
  );
};
  
export default NewArrivals;
  

  
  
  
  
  
  
  
  
