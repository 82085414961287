import React, {useContext, useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import $ from 'jquery';
import './HamburgerMenu.css';
import { ShopContext } from '../../Context/ShopContext';
import useAuth from '../../hooks/useAuth';

import cart from '../Assets/icons/cart-white.png';

const HamburgerMenu = () => {
    const api_url = process.env.REACT_APP_API_URL;
    const api_key = process.env.REACT_APP_API_KEY;
    const client_id = process.env.REACT_APP_API_CLIENT_ID;

    const { isAuthenticated, token, cid } = useAuth();
    const { getTotalCartItems } = useContext(ShopContext);
    const [isScrolled, setIsScrolled] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [customer, setCustomer] = useState({});

    const handleToggle = () => {
        setIsOpen(!isOpen);
    }

    useEffect(() => {
        if (isOpen) {
            $('.sidebar').animate({left: '0px'}, 200);
        } else {
            $('.sidebar').animate({left: '-100%'}, 200);
        }
    }, [isOpen]);

    useEffect(() => {
        if (isAuthenticated && cid) {
          fetch(`${api_url}/getcustomer`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
                'x-api-key': api_key,
                'x-client-id': client_id,
            },
            body: JSON.stringify({ cid: cid }),
          })
          .then(res => res.json())
          .then(data => {
            setCustomer(data.customer);
          })
          .catch();
        }
      }, [isAuthenticated, cid]);

  return (
    <div className='hamburger-menu'>
        <div className="menu-icon" onClick={handleToggle}>
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
        </div>
        <div className="sidebar">
            <div className="close-icon" onClick={handleToggle}>
                <div className="bar"></div>
                <div className="bar"></div>
            </div>
            {isAuthenticated && customer ?
            <div className="user-details">
                <div className="profile-icon"></div>
                <div className="name">{customer.first_name}</div>
                <div className="email">{customer.email}</div>
            </div> : 
            <div className="user-details">
                <div className="profile-icon"></div>
                <div className="name">Guest</div>
                <div className="email">Welcome to Aizen Styles</div>
            </div>
            }
            <div className="menu">
                <div className="primary-menu">
                    <Link to="/" onClick={handleToggle}>
                        <div className="menu-item">
                            <span>Home</span>
                        </div>
                    </Link>
                    <Link to="/men" onClick={handleToggle}>
                        <div className="menu-item">
                            <span>Men</span>
                        </div>
                    </Link>
                    <Link to="/women" onClick={handleToggle}>
                        <div className="menu-item">
                            <span>Women</span>
                        </div>
                    </Link>
                    <Link to="/unisex" onClick={handleToggle}>
                        <div className="menu-item">
                            <span>Unisex</span>
                        </div>
                    </Link>
                    <Link to="/anime" onClick={handleToggle}>
                        <div className="menu-item">
                            <span>Anime</span>
                        </div>
                    </Link>
                    {/* <Link to="/about" onClick={handleToggle}>
                        <div className="menu-item">
                            <span>About Us</span>
                        </div>
                    </Link> */}
                </div>
                
                {isAuthenticated ?
                    <div className="line"></div>
                : null}

                <div className="secondary-menu">
                    {/* <Link to="/account" onClick={handleToggle}>
                        <div className="menu-item">
                            <i className="bi bi-person"></i>
                            <span>Account</span>
                        </div>
                    </Link> */}
                    {/* <Link to="/address" onClick={handleToggle}>
                        <div className="menu-item">
                            <i className="bi bi-geo-alt"></i>
                            <span>Your Addresses</span>
                        </div>
                    </Link> */}
                    {isAuthenticated ?
                    <Link to="/orders" onClick={handleToggle}>
                        <div className="menu-item">
                            <i className="bi bi-box-seam"></i> 
                            <span>Orders</span>
                        </div>
                    </Link>
                    : null}
                    {/* <Link to="/wishlist" onClick={handleToggle}>
                        <div className="menu-item">
                            <i className="bi bi-heart"></i>
                            <span>Wishlist</span>
                        </div>
                    </Link> */}
                </div>
            </div>
            <div className="menu-cta">
                <div className="cart-cta cta-left cta-btn">
                    <Link to="/cart" onClick={handleToggle}>
                        <div className="cart-icon">
                            <img src={cart} alt="" />
                            <div className='cart-bubble'>
                                <span>{getTotalCartItems()}</span>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="line"></div>
                <div className="login-cta cta-right cta-btn">
                {isAuthenticated ?
                <Link to="/logout" onClick={handleToggle}>
                    <span>Logout</span>
                </Link> : <Link to="/login" onClick={handleToggle}>
                    <span>Login</span>
                </Link>}
                </div>
            </div>
        </div>
    </div>
  )
}

export default HamburgerMenu
