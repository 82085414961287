import React, { useEffect } from 'react'
import './css/Home.css'

import Hero from '../Components/Hero/Hero'
import NewArrivals from '../Components/NewArrivals/NewArrivals'
import Offers from '../Components/Offers/Offers'
import Collections from '../Components/Collections/Collections'
import Bestsellers from '../Components/Bestsellers/Bestsellers'

import SocialFloat from '../Components/SocialFloat/SocialFloat'

import TopFeatured from '../Components/TopFeatured/TopFeatured'

const Home = () => {

  useEffect(() => {
    // search engine friendly content
    document.title = 'Aizen Styles: Online Genz Fashion Shopping For Men, Women, Unisex. One Stop Shop For Streetwear Fashion and Anime Lovers, Shop Now!';
    document.querySelector('meta[name="description"]').setAttribute("content", "Shop the latest fashion trends at Aizen Styles an online shopping platform. Discover the latest trends in the world of fashion. We offer a wide range of products ranging from streetwear to fashion for anime lovers.");

  }, []);

  return (
    <div className='home'>
        <Hero brand_name="AIZEN" brand_moto="Born from a passion for creativity and an unwavering commitment to authenticity, our brand celebrates the artistry of fashion in all its forms. With a fusion of innovation and tradition, we curate collections that transcend trends, offering timeless pieces that effortlessly elevate any wardrobe." brand_cta="Style With Aizen" />
        <NewArrivals/>
        <TopFeatured/>
        <Offers/>
        <Collections />
        <Bestsellers />
        <SocialFloat/>
    </div>
  )
}

export default Home
