import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Item from '../Components/Item/Item';

import './css/SearchResults.css';

const SearchResults = () => {
    const api_url = process.env.REACT_APP_API_URL;
    const api_key = process.env.REACT_APP_API_KEY;
    const client_id = process.env.REACT_APP_API_CLIENT_ID;

    const location = useLocation();
    const [query, setQuery] = useState('');

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const queryValue = params.get('query');
        setQuery(queryValue);
        // Perform your search logic here with the queryValue
    }, [location.search]);

    const [products, setProducts] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (query) {
            fetch(`${api_url}/searchproducts`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': api_key,
                    'x-client-id': client_id,
                },
                body: JSON.stringify({ query }),
            })
            .then((res) => res.json())
            .then((data) => {
                setProducts(data.products);
            })
            .catch((err) => {
            });
        }
    }, [query]);

  return (
    <div className='search-results'>
        <div className="search-head">
            <h1 className='search-title'>{`Search Results for: ${query}`}</h1>
        </div>
        <div className="products">
            {products && products.length > 0 ? 
            <div className="items">
            {products.map((item,i)=>{
                return <Item key={i} pid={item.pid} name={item.name} description={item.description} images={item.images} colors={item.colors}/>
            })}
            </div> :
            <div className="message">
                <h4>{`Oops, Nothing is here. Please come back later :)`}</h4>
            </div>}
        </div>
    </div>
  )
}

export default SearchResults
