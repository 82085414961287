import React, { useEffect, useRef, useState } from 'react'
import $, { cleanData } from 'jquery';
import './Collections.css'

const Collections = () => {
  const api_url = process.env.REACT_APP_API_URL;
  const api_key = process.env.REACT_APP_API_KEY;
  const client_id = process.env.REACT_APP_API_CLIENT_ID;

  const itemsRef = useRef(null);
  const [collections, setCollections] = useState([]);

  useEffect(() => {
    fetch(`${api_url}/allcollections`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': api_key,
            'x-client-id': client_id,
        },
    })
    .then((res) => res.json())
    .then((data) => {
        if (data.success) {
            setCollections(data.collections);
        }
    });
  }, []);

  useEffect(() => {
    const items = $(itemsRef.current);
    const itemWidth = items.find('.item').first().outerWidth(true); // Width of each item including margin
    const delay = 3500; // Delay between each slide in milliseconds
    let positionOffset = 0; // Initial position offset

    // Function to slide the items
    const slideItems = () => {
      positionOffset -= itemWidth; // Decrement position offset
      items.animate({ left: positionOffset }, 500, 'swing', () => {
        // Move first item to the end for wrapping effect
        items.append(items.find('.item:first-child'));
        positionOffset += itemWidth; // Increment position offset after wrapping
        items.css('left', positionOffset); // Reset position offset
      });
    };

    // Automatically slide items with delay
    let slideInterval = setInterval(slideItems, delay);

    // Click event for left arrow button
    $('.collections .left').off('click').on('click', () => {
        clearInterval(slideInterval);
      positionOffset += itemWidth; // Increment position offset
      items.animate({ left: positionOffset }, 500, 'swing', () => {
        // Move last item to the beginning for wrapping effect
        items.prepend(items.find('.item:last-child'));
        positionOffset -= itemWidth; // Decrement position offset after wrapping
        items.css('left', positionOffset); // Reset position offset
        slideInterval = setInterval(slideItems, delay);
      });
    });

    // Click event for right arrow button
    $('.collections .right').off('click').on('click', () => {
        clearInterval(slideInterval);
      positionOffset -= itemWidth; // Decrement position offset
      items.animate({ left: positionOffset }, 500, 'swing', () => {
        // Move first item to the end for wrapping effect
        items.append(items.find('.item:first-child'));
        positionOffset += itemWidth; // Increment position offset after wrapping
        items.css('left', positionOffset); // Reset position offset
        slideInterval = setInterval(slideItems, delay);
      });
    });

    // Clean up interval on component unmount
    return () => clearInterval(slideInterval);
  }, [collections]);

  const handleViewCollection = (collection) => {
    window.location = `/collection/${collection.toLowerCase().replace(/\s+/g, '-')}`;
  }
  return (
    <div className='collections'>
        <div className="section-head">
            <h1 className="section-title">Collections</h1>
            <h3 className='section-description'>Discover our curated collections of timeless pieces that effortlessly elevate any wardrobe.</h3>
        </div>
        <div className="slider">
            <div className="left arrow-btn">
                <i className="fas fa-chevron-left"></i>
            </div>
            <div ref={itemsRef} className="items">
                {collections && collections.map((collection,i)=>{
                    return <div key={i} className="item" onClick={()=>handleViewCollection(collection.name)}>
                        <div className="collection-image">
                            <img src={collection.image} alt="" />
                        </div>
                        <div className="collection-name"><span>{collection.name}</span></div>
                    </div>
                })}
            </div>
            <div className="right arrow-btn">
                <i className="fas fa-chevron-right"></i>
            </div>
        </div>
    </div>
  )
}

export default Collections
