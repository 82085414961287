import React from 'react'
import './Offers.css'
import $ from 'jquery';

import banner1 from '../Assets/offers/banner1.png'
import banner2 from '../Assets/offers/banner2.png'

const Offers = () => {

    React.useEffect(() => {
        let offers_currentIndex = 0;
        const offers_banners = $('.offers .slider .banner .banner-img');
        const offers_dots = $('.offers .slider-position .slider-position-dot');

        function slideShow() {
            offers_banners.removeClass('active');
            offers_dots.removeClass('active');
            offers_currentIndex++;
            if (offers_currentIndex >= offers_banners.length) {
                offers_currentIndex = 0;
            }
            $('.offers .slider .banner .banner-img').css('transform', 'translateX(' + (-offers_currentIndex * 100) + '%)');
            offers_banners.eq(offers_currentIndex).addClass('active');
            offers_dots.eq(offers_currentIndex).addClass('active');
        }
        

        const offers_interval = setInterval(slideShow, 3000);

        return () => {
            clearInterval(offers_interval);
        }
    }, []);
  return (
    <div className='offers'>
        <div className="section-head">
            <h1 className='section-title'>Offers and <span>Deals</span></h1>
            <h3 className="section-description">Avail epic discounts and deals - just for you</h3>
        </div>
        <div className="slider">
            <div className="banner">
                <div className="banner-img">
                    <img src={banner1} alt="" />
                </div>
                <div className="banner-img">
                    <img src={banner2} alt="" />
                </div>
            </div>
        </div>
        <div className='slider-position'>
            <div className='slider-position-dot active'></div>
            <div className='slider-position-dot'></div>
        </div>
    </div>
  )
}

export default Offers
