import React, { useEffect, useState } from 'react'
import './OrderConfirmed.css'
import { Link } from 'react-router-dom'

const OrderConfirmed = (props) => {
    const api_url = process.env.REACT_APP_API_URL;
    const api_key = process.env.REACT_APP_API_KEY;
    const client_id = process.env.REACT_APP_API_CLIENT_ID;
    
    const [paymentMode, setPaymentMode] = useState(props.paymentMode);
    const [orderId, setOrderId] = useState(props.orderId);
    const [orderStatus, setOrderStatus] = useState(null);
    const [orderDetails, setOrderDetails] = useState(null);
    const [productDetails, setProductDetails] = useState([]);
    const [customerDetails, setCustomerDetails] = useState(null);

    useEffect(() => {
        if (paymentMode==='cod'){
            fetch(`${api_url}/getorder`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': api_key,
                    'x-client-id': client_id,
                },
                body: JSON.stringify({ oid: orderId }),
            })
            .then(response => response.json())
            .then(data => {
                if (data.success){
                    console.log('Order details response:', data);
                    setOrderDetails(data.order);
                }
            })
        }
        else{
            fetch(`${api_url}/verifyorder`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': api_key,
                    'x-client-id': client_id,
                },
                body: JSON.stringify({ orderId: orderId }),
            })
            .then(response => response.json())
            .then(data => {
                if (data.success){
                    console.log('Order verification response:', data);
                    setOrderStatus(data.order_status);

                    fetch(`${api_url}/getorder`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'x-api-key': api_key,
                            'x-client-id': client_id,
                        },
                        body: JSON.stringify({ oid: orderId }),
                    })
                    .then(response => response.json())
                    .then(data => {
                        if (data.success){
                            console.log('Order details response:', data);
                            setOrderDetails(data.order);
                        }
                    })
                }
            })
        }
    },[props]);

    useEffect(() => {
        if (orderDetails!=null) {
            if (productDetails.length===0){
                orderDetails.order_items.map(product => {
                    fetch(`${api_url}/getproductbypid`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'x-api-key': api_key,
                            'x-client-id': client_id,
                        },
                        body: JSON.stringify({ pid: product.pid }),
                    })
                    .then(response => response.json())
                    .then(data => {
                        console.log('Order Details:', orderDetails);
                        if (data.success){
                            console.log('Product details response:', data);
                            setProductDetails([...productDetails, data.product]);
                            
                            fetch(`${api_url}/getcustomer`, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                    'x-api-key': api_key,
                                    'x-client-id': client_id,
                                },
                                body: JSON.stringify({ cid: orderDetails.cid }),
                            })
                            .then(response => response.json())
                            .then(data => {
                                if (data.success){
                                    console.log('Customer details response:', data);
                                    setCustomerDetails(data.customer);
                                }
                            })
                        }
                    });
                });
            }
    
        }
        {console.log('Order ID:', orderId, 'Payment Mode:', paymentMode, 'Order Status:', orderStatus, 'Order Details:', orderDetails, 'Product Details:', productDetails, 'Customer Details:', customerDetails)}
    }, [orderDetails]);

    const handleContinue = () => {
        window.location.href = '/orders';
    }

  return (
    <div className="order-confirmed">
        <div className="main">
            {orderId && orderDetails && productDetails && customerDetails &&
            <>
            <div className="order-image">
                {/* <img src={color.images[0]} alt="order" /> */}
            </div>
            <div className="order-info">
                <div className="note">
                    <h4 className='title'>Hey, {customerDetails.first_name} {`:)`}</h4>
                    <p className='message'>Your order is {orderDetails.order_status}.<br />You will receive an email when your order is ready.</p>
                </div>
            </div>
            <div className="cta">
                <div className="btn continue" onClick={handleContinue}>Go To Orders</div>
            </div>
            </>
            }
        </div>
    </div>
  )
}

export default OrderConfirmed;