import React, {useContext, useEffect, useState} from 'react'
import './css/Cart.css'
import { Link } from 'react-router-dom'
import { ShopContext } from '../Context/ShopContext'

import useAuth from '../hooks/useAuth'

const Cart = () => {
    const api_url = process.env.REACT_APP_API_URL;
    const api_key = process.env.REACT_APP_API_KEY;
    const client_id = process.env.REACT_APP_API_CLIENT_ID;

    const {isAuthenticated, cid} = useAuth();
    const {cartItems, addToCart, removeFromCart} = useContext(ShopContext);
    const [products, setProducts] = useState([]);

    const [totalAmount, setTotalAmount] = useState(0);
    useEffect(() => {
        let total = 0;
        if (cartItems){
            cartItems.forEach(item => {
                item.color.sizes.forEach(size => {
                    if (size.size === item.size) {
                        total += size.sellingPrice * item.quantity;
                    }
                });
            });
        }
        setTotalAmount(total);
    }, [cartItems]);

    const handleCartProceed = () => {
        if(isAuthenticated)
        {
            localStorage.setItem('checkout', true);
            window.location = '/checkout';
        }
        else
        {
            window.location = '/login';
        }
    }

    useEffect(() => {
        fetch(`${api_url}/allproducts`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': api_key,
                'x-client-id': client_id,
            }
        })
        .then(response => response.json())
        .then(data => {
            setProducts(data);
        });
    }, [cartItems]);

    return (
        <div className="shopping-cart">
            <h2>Your Shopping Cart</h2>
            <div className="cart-items">
                {cartItems && cartItems.length > 0 ? cartItems.map((item, i) => 
                <>
                    <div key={i} className="cart-item">
                        <div className="item-left">
                            <div className="product-image">
                                <img src={item.color.images[0]} alt="" />
                            </div>
                        </div>
                        <div className="item-right">
                            <div className="product-details">
                                    {products.map(product => {
                                        if (product.pid === item.pid) {
                                            return (
                                                <div className="product-title">
                                                    <Link to={`/product/${item.pid}`}>{product.name}</Link>
                                                </div>
                                            )
                                        }
                                    })}
                                <div className="product-price">
                                    {item.color.sizes.map(size => {
                                        if (size.size === item.size) {
                                            return (<span>&#8377; {size.sellingPrice}</span>)
                                        }
                                    })}
                                </div>
                                <div className="product-size">
                                    <span><span className='bold'>Size: </span>{item.size}</span>
                                </div>
                                <div className="product-color">
                                    <span><span className='bold'>Color: </span>{item.color.name}</span>
                                </div>
                                <div className="product-quantity">
                                    <button className="quantity-btn quantity-box" onClick={() => removeFromCart(item.pid, item.color, item.size, item.quantity, 1)}>-</button>
                                    <div className='quantity-value quantity-box'>{item.quantity}</div>
                                    <button className="quantity-btn quantity-box" onClick={() => addToCart(item.pid, item.color, item.size)}>+</button>
                                </div>
                            </div>
                            <div className="product-cta">
                                <button className="wishlistBtn" hidden>Add To Wishlist</button>
                                <button className="deleteBtn" onClick={() => removeFromCart(item.pid, item.color, item.size)}>Delete</button>
                            </div>
                        </div>
                    </div>
                </>
                ) : <h4>Your Cart is Empty</h4>}
                {
                cartItems && cartItems.length > 0 &&
                <>
                <div className="cart-summary">
                    <h3 className='summary-title'>Cart Summary</h3>
                    <div className="summary-details">
                        <div className="cart-total">
                            <span>Total Amount: </span>
                            <span>&#8377; {totalAmount}</span>
                        </div>
                    </div>
                </div>
                <div className="cart-cta">
                    <button className="checkoutBtn" onClick={handleCartProceed}>Proceed To buy</button>
                </div>
                </>
                }
            </div>
        </div>
    );
};

export default Cart;