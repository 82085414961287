import React, {useEffect, useState} from 'react';
import './Overlay.css'; // Create a CSS file for overlay styles

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as fas from '@fortawesome/free-solid-svg-icons';

const Overlay = ({ isVisible, onClose }) => {

    const [search, setSearch] = useState('');
    
    useEffect(() => {
        if (isVisible){
            document.getElementById('search').focus();
        }
    }, [isVisible]);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            const query = encodeURIComponent(search.toLowerCase());
            setSearch('');
            window.location.href = `/search?query=${query}`;
        }
    }
    const changeHanler = (e) => {
        setSearch(document.getElementById('search').value);
    }

    if (!isVisible) return null;
    
    return (
    <div className="overlay">
      <div className="overlay-content">
        <div className='overlay-close' onClick={onClose}>
            <FontAwesomeIcon icon={fas.faTimes} />
        </div>
        <div className="search-bar">
            <input type="text" name="search" id="search" value={search} onChange={changeHanler} onKeyDown={handleKeyDown} />
            <FontAwesomeIcon icon={fas.faSearch} />
        </div>
      </div>
    </div>
  );
};

export default Overlay;