import './App.css';

import Header from './Components/Header/Header';
import Promo from './Components/Promo/Promo';

import {BrowserRouter, Routes, Route} from 'react-router-dom';

import Home from './Pages/Home';
import Footer from './Components/Footer/Footer';
import Shop from './Pages/Shop';
import FreeformShopLayout from './Pages/FreeformShopLayout';
import Product from './Pages/Product';
import Cart from './Pages/Cart';
import Login from './Pages/Login';
import Logout from './Components/Logout/Logout';
import Regsiter from './Pages/Register';
import ForgotPassword from './Pages/ForgotPassword';
import ResetPassword from './Pages/ResetPassword';
import Checkout from './Pages/Checkout';
import Account from './Components/Account/Account';
import Address from './Components/Address/Address';
import Orders from './Components/Orders/Orders';
import Overlay from './Components/Overlay/Overlay';
import SearchResults from './Pages/SearchResults';

import ShippingPolicy from './Pages/ShippingPolicy';
import RefundCancellation from './Pages/RefundCancellation';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import ReturnPolicy from './Pages/ReturnPolicy';
import TermsAndConditions from './Pages/TermsAndConditions';
import PaymentConfirmation from './hooks/PaymentConfirmation';
import OrderDetails from './Components/OrderDetails/OrderDetails';
import CollectionList from './Pages/CollectionList';
import { useState } from 'react';

function App() {

  const [isOverlayVisible, setOverlayVisible] = useState(false);

  const handleSearchClick = () => {
    setOverlayVisible(true);
  };

  const handleCloseOverlay = () => {
    setOverlayVisible(false);
  };

  return (
    <div className='App'>
      <BrowserRouter>
        <div className="top-bar">
          <Promo text="SHOP NOW WITH AIZEN" />
          <Header onSearchClick={handleSearchClick}/>
        </div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/men" element={<FreeformShopLayout category="men" title="Men" />} />
          <Route path="/women" element={<FreeformShopLayout category="women" title="Women" />} />
          <Route path="/unisex" element={<FreeformShopLayout category="unisex" title="Unisex" />} z/>
          <Route path="/anime" element={<FreeformShopLayout category="anime" title="Anime" />} />
          <Route path="/product" element={<Product/>}>
            <Route path=':productId' element={<Product/>}/>
          </Route>
          <Route path="/collection" element={<CollectionList/>}>
            <Route path=':collection' element={<CollectionList/>}/>
          </Route>
          <Route path="/cart" element={<Cart/>} />
          <Route path="/checkout" element={<Checkout/>} />
          <Route path="/login" element={<Login/>} />
          <Route path="/logout" element={<Logout/>}/>
          <Route path="/register" element={<Regsiter/>} />
          <Route path="/forgotpassword" element={<ForgotPassword/>} />
          <Route path="/resetpassword" element={<ResetPassword/>} />
          <Route path="/account" element={<Account/>} />
          <Route path="/address" element={<Address/>} />
          <Route path="/orders" element={<Orders/>} />

          <Route path="/search" element={<SearchResults/>} />
          {/* <Route path="/about" />
          <Route path="/contact" /> */}
          <Route path="/policy/shipping-policy" element={<ShippingPolicy/>}/>
          <Route path="/policy/refund-and-cancellation" element={<RefundCancellation/>}/>
          <Route path="/policy/privacy-policy" element={<PrivacyPolicy/>}/>
          <Route path="/policy/return-policy" element={<ReturnPolicy/>}/>
          <Route path="/policy/terms-and-conditions" element={<TermsAndConditions/>}/>

          <Route path="/paymentconfirmation" element={<PaymentConfirmation/>} />
          <Route path="/order" element={<OrderDetails/>}>
            <Route path=':orderId' element={<OrderDetails/>}/>
          </Route>
          {/* <Route path="*" element={<h1>404 Not Found</h1>} /> */}
          <Route path="*" element={<Home/>} />
        </Routes>
        <Footer />
        <Overlay isVisible={isOverlayVisible} onClose={handleCloseOverlay} />
      </BrowserRouter>
    </div>
  );
}

export default App;
