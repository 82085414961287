import React from 'react'
import './Hero.css'
import $ from 'jquery'

const Hero = (props) => {
    const handleCta = () => {
        $('html, body').animate({
            scrollTop: $('.new-arrivals').offset().top
        }, 1000);
    }
  return (
    <div className='hero'>
        <div className="bg-effect"></div>
        <div className="branding">
            <div className="brand-name">
                <span>{props.brand_name}</span>
            </div>
            <div className="brand-moto">
                <p>{props.brand_moto}</p>
            </div>
            <div className="brand-cta" onClick={handleCta}>
                {props.brand_cta}
            </div>
        </div>
    </div>
  )
}

export default Hero