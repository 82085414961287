import React, { useEffect, useState } from 'react'
import './TopFeatured.css'
import ImageCarousel1 from '../Image-carousels/image-carousel-1/image-carousel-1'

const TopFeatured = () => {
    const api_url = process.env.REACT_APP_API_URL;
    const api_key = process.env.REACT_APP_API_KEY;
    const client_id = process.env.REACT_APP_API_CLIENT_ID;

    const [featured, setFeatured] = useState([]);
    const [featuredGroups, setFeaturedGroups] = useState([]);
    const [featuredImagesGroups, setFeaturedImagesGroups] = useState([]);
    const totalFeaturedGroups = 2;

    useEffect(() => {
        fetch(`${api_url}/featured`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': api_key,
                'x-client-id': client_id,
            },
        })
        .then((res) => res.json())
        .then((data) => {
            if (data.success) {
                data.products.reverse();
                setFeatured(data.products);
            }
        });
    }, []);

    useEffect(() => {
        // if featured products = 4, show totalFeaturedGroups of 2 products, if more than 4, divide in half and show totalFeaturedGroups and at least 2 products in each group
        if (featured.length >= 4) {
            if (totalFeaturedGroups !== 1) {
                const groups = [];
                const groupSize = Math.ceil(featured.length / totalFeaturedGroups);
                for (let i = 0; i < totalFeaturedGroups; i++) {
                    groups.push(featured.slice(i * groupSize, i * groupSize + groupSize));
                }
                setFeaturedGroups(groups);
                // set featured images groups
                const imagesGroups = [];
                groups.forEach((group) => {
                    const images = group.map((product) => ({
                        id: product.pid,
                        imageSrc: product.colors[0].images[0],
                    }));
                    imagesGroups.push(images);
                });
                setFeaturedImagesGroups(imagesGroups);
            } else {
                setFeaturedGroups([featured]);
            }
        }
    }, [featured]);

    // if featured products not equal to 4 or more, return null
    if (featured && featured.length < 4) {
        return null;
    }

  return (
    <div className='top-featured'>
        <div className="section-head">
            <h1 className='section-title'>
                Top <span>Featured</span>
            </h1>
            <h3 className='section-description'>
                {/* genz subheading */}
                <span>Genz</span> fashion trends that are making waves in the fashion world. Stay ahead of the curve with our top picks!
            </h3>
        </div>
        <div className="featured-blocks">
            {featured && featuredGroups && featuredGroups.length > 0 && featuredImagesGroups.map((group, index) => (
                <div className="featured-group" key={index}>
                    <ImageCarousel1 images={group} />
                </div>
            ))}
        </div>
    </div>
  )
}

export default TopFeatured
