import React, { useState, useEffect } from "react"
import { motion, AnimatePresence } from "framer-motion"
import { wrap } from "@popmotion/popcorn"

import "./image-carousel-1.css"

const sliderVariants = {
  incoming: direction => ({
    x: direction > 0 ? "100%" : "-100%",
    scale: 1.2,
    opacity: 0
  }),
  active: { x: 0, scale: 1, opacity: 1 },
  exit: direction => ({
    x: direction > 0 ? "-100%" : "100%",
    scale: 1,
    opacity: 0.2
  })
}

const sliderTransition = {
  duration: 1,
  ease: [0.56, 0.03, 0.12, 1.04]
}

const ImageCarousel1 = (props) => {

    const api_url = process.env.REACT_APP_API_URL;
    const api_key = process.env.REACT_APP_API_KEY;
    const client_id = process.env.REACT_APP_API_CLIENT_ID;

    const IMAGES = props.images;

  const [[imageCount, direction], setImageCount] = useState([0, 0])

  const activeImageIndex = wrap(0, IMAGES.length, imageCount)

  const swipeToImage = swipeDirection => {
    setImageCount([imageCount + swipeDirection, swipeDirection])
  }

  const dragEndHandler = dragInfo => {
    const draggedDistance = dragInfo.offset.x
    const swipeThreshold = 50
    if (draggedDistance > swipeThreshold) {
      swipeToImage(-1)
    } else if (draggedDistance < -swipeThreshold) {
      swipeToImage(1)
    }
  }

  const skipToImage = imageId => {
    let changeDirection
    if (imageId > activeImageIndex) {
      changeDirection = 1
    } else if (imageId < activeImageIndex) {
      changeDirection = -1
    }
    setImageCount([imageId, changeDirection])
  }

  // Auto image change every 2 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      swipeToImage(1)
    }, 2000)

    return () => clearInterval(interval)  // Cleanup interval on component unmount
  }, [imageCount])

    const handleProductClick = (productId) => {
        // get product by pid using api
        fetch(`${api_url}/getproductbypid`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': api_key,
                'x-client-id': client_id,
            },
            body: JSON.stringify({
                pid: productId,
            }),
        })
        .then((res) => res.json())
        .then((data) => {
            if (data.success) {
                console.log(data.product);
                window.location.href = `/product/${productId}`;
            }
        });
    }

  return (
    <main>
        <div className="slider-container">
            <div className="slider">
                <AnimatePresence initial={false} custom={direction}>
                <motion.div
                    key={imageCount}
                    style={{
                    backgroundImage: `url(${IMAGES[activeImageIndex].imageSrc})`
                    }}
                    custom={direction}
                    variants={sliderVariants}
                    initial="incoming"
                    animate="active"
                    exit="exit"
                    transition={sliderTransition}
                    drag="x"
                    dragConstraints={{ left: 0, right: 0 }}
                    dragElastic={1}
                    onDragEnd={(_, dragInfo) => dragEndHandler(dragInfo)}
                    className="image"
                    onClick={() => handleProductClick(IMAGES[activeImageIndex].id)}
                />
                </AnimatePresence>
                <div className="buttons">
                    <div className="blur-overlay"></div>
                    <button className="prevBtn" onClick={() => swipeToImage(-1)}>PREV</button>
                    <button className="nextBtn" onClick={() => swipeToImage(1)}>{`--->`}</button>
                </div>
            </div>
        </div>
    </main>
  )
}

export default ImageCarousel1
